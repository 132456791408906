import { useEffect, useState } from 'react';

import cx from 'classnames';

import { useBreakpoint } from '~/ui/components/grid/useBreakpoint';
import { Breakpoint } from '~/ui/styles/grid';
import { type Footer_ideas } from '~/v1/_types/Footer';
import { TEST_ID } from '~/v1/constants/testId';

import { Idea } from './idea';
import { IdeasMarquee, type IdeasMarqueeProps } from './marquee';
import styles from '../footer.module.scss';

function splitArray<T>(arr: T[], n: number): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < n; i++) {
    result.push([]);
  }
  for (let i = 0; i < arr.length; i++) {
    result[i % n].push(arr[i]);
  }
  return result;
}

export interface IdeasProps {
  ideas: Footer_ideas[];
}

export function AnimatedIdeas({ ideas }: IdeasProps): React.ReactElement {
  const [marqueeRows, setMarqueeRows] = useState<
    Pick<IdeasMarqueeProps, 'ideas' | 'speed' | 'direction'>[]
  >([]);

  useEffect(() => {
    const splitIdeas = splitArray(ideas, 3);
    setMarqueeRows([
      {
        ideas: splitIdeas[2],
        speed: 12,
        direction: 'normal',
      },
      {
        ideas: splitIdeas[1],
        speed: 16,
        direction: 'reverse',
      },
      {
        ideas: splitIdeas[0],
        speed: 24,
        direction: 'normal',
      },
    ]);
  }, [ideas]);

  return (
    <div className={styles.ideas} data-test-id={TEST_ID.FOOTER.IDEAS}>
      {marqueeRows.map(({ ideas, speed, direction }, index) => (
        <IdeasMarquee ideas={ideas} speed={speed} direction={direction} key={index} />
      ))}
    </div>
  );
}

export function StaticIdeas({ ideas }: IdeasProps): React.ReactElement {
  const breakpoint = useBreakpoint();

  return (
    <ul className={cx(styles.ideas, styles.staticIdeas)} data-test-id={TEST_ID.FOOTER.IDEAS}>
      {ideas.map((idea, index) => (
        <li key={index}>
          <Idea idea={idea} showSeparator={breakpoint === Breakpoint.LG} />
        </li>
      ))}
    </ul>
  );
}
