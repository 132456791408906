import { Fragment, useEffect, useRef, useState } from 'react';

import { type Footer_ideas } from '~/v1/_types/Footer';
import { Idea } from '~/v1/modules/footer/ideas/idea';

import styles from './marquee.module.scss';

export interface IdeasMarqueeProps {
  ideas: Footer_ideas[];

  /** CSS animation-direction property */
  direction: React.CSSProperties['animationDirection'];

  /** Pixels per second */
  speed: number;
}

export function IdeasMarquee({ ideas, direction, speed }: IdeasMarqueeProps): React.ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const marqueeInnerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [marqueeInnerWidth, setMarqueeInnerWidth] = useState(0);
  const [playing, setPlaying] = useState(true);

  const mapIdeas = () =>
    ideas.map((idea, index) => (
      <Idea
        idea={idea}
        onMouseOver={() => setPlaying(false)}
        onFocus={() => setPlaying(false)}
        onMouseOut={() => setPlaying(true)}
        onBlur={() => setPlaying(true)}
        key={index}
        showSeparator
      />
    ));

  const multiplyChildren = (m: number) => {
    return [...Array(Number.isFinite(m) && m >= 0 ? m : 0)].map((_, i) => (
      <Fragment key={i}>{mapIdeas()}</Fragment>
    ));
  };

  useEffect(() => {
    const container = containerRef.current;
    const marqueeInner = marqueeInnerRef.current;
    if (!container || !marqueeInner) return;

    const resizeObserver = new ResizeObserver(() => {
      const containerRect = container.getBoundingClientRect();
      const marqueeRect = marqueeInner.getBoundingClientRect();
      setContainerWidth(containerRect.width);
      setMarqueeInnerWidth(marqueeRect.width);
    });
    resizeObserver.observe(container);
    resizeObserver.observe(marqueeInner);
    return () => resizeObserver.disconnect();
  }, []);

  const multiplier =
    marqueeInnerWidth < containerWidth ? Math.ceil(containerWidth / marqueeInnerWidth) : 1;
  const duration = (marqueeInnerWidth * multiplier) / speed;

  const marqueeStyle: React.CSSProperties = {
    animationDirection: direction,
    animationDuration: `${duration}s`,
    animationPlayState: playing ? 'running' : 'paused',
    animationDelay: `${-10 * speed}s`,
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.marquee} style={marqueeStyle}>
        <div className={styles.marqueeInner} ref={marqueeInnerRef}>
          {mapIdeas()}
        </div>
        {multiplyChildren(multiplier - 1)}
      </div>
      <div className={styles.marquee} style={marqueeStyle}>
        {multiplyChildren(multiplier)}
      </div>
    </div>
  );
}
