import { Locale } from '~/contentful/model/locale';

export function formatDate(
  date: string | Date,
  options?: { pattern?: Intl.DateTimeFormatOptions; locale?: Locale },
) {
  const {
    pattern = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    },
    locale = Locale.EN_US,
  } = options || {};

  const dateTimeFormat = new Intl.DateTimeFormat(locale, pattern);
  return dateTimeFormat.format(new Date(date));
}
