import { createContext, useContext } from 'react';

export const FullBleedCTAScopeContext = createContext(false);
export const FullBleedCTATriggerContext = createContext(false);

export function useIsInFullBleedCTA(): boolean {
  return useContext(FullBleedCTAScopeContext);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useIsFullBleedCTATrigger(props: unknown): boolean {
  return useContext(FullBleedCTATriggerContext);
}
