'use client';

import { forwardRef } from 'react';

import cx from 'classnames';

import styles from './expandingArrow.module.scss';

export const Direction = {
  LEFT: 'left',
  RIGHT: 'right',
  DIAGONAL: 'diagonal',
} as const;
export type Direction = (typeof Direction)[keyof typeof Direction];

export type ExpandingArrowProps = {
  direction: Direction;
  className?: string;
} & Omit<React.ComponentPropsWithoutRef<'svg'>, 'viewBox' | 'xmlns'>;

export const ExpandingArrow = forwardRef(ExpandingArrowImpl);
function ExpandingArrowImpl(
  { direction, className, ...props }: ExpandingArrowProps,
  forwardedRef: React.Ref<SVGSVGElement>,
): React.ReactElement {
  return (
    <svg
      viewBox="0 0 12 10"
      fill="none"
      className={cx(styles.icon, className, {
        [styles.right]: direction === Direction.RIGHT,
        [styles.left]: direction === Direction.LEFT,
        [styles.diagonal]: direction === Direction.DIAGONAL,
      })}
      ref={forwardedRef}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d={getIconPath(direction)} />
    </svg>
  );
}

function getIconPath(direction: Direction): string {
  switch (direction) {
    case Direction.RIGHT:
      return 'M10.2448 5.08525L1.05498e-05 5.08525V3.96025L10.2448 3.96025L7.08 0.795495L7.8755 0L12.0005 4.125V4.9205L7.8755 9.0455L7.08 8.25L10.2448 5.08525Z';
    case Direction.LEFT:
      return 'M1.75622 3.96022L12.001 3.96022L12.001 5.08522L1.75622 5.08522L4.92097 8.24998L4.12548 9.04547L0.000476244 4.92047L0.000476314 4.12497L4.12548 -2.54876e-05L4.92097 0.79547L1.75622 3.96022Z';
    case Direction.DIAGONAL:
      return 'M8.39952 2.83733L1.15539 10.0815L0.359898 9.28597L7.60403 2.04184L3.12839 2.04184L3.12839 0.916837H8.96202L9.52452 1.47934V7.31297L8.39952 7.31297L8.39952 2.83733Z';
  }
}
