'use client';

import { resolveHref } from 'next/dist/client/resolve-href';
import NextLink, { type LinkProps as BaseNextLinkProps } from 'next/link';
import Router from 'next/router';
import { Fragment, forwardRef } from 'react';

import cx from 'classnames';

import { type TrackEventProps, trackEvent } from '~/ui/components/analytics/trackEvent';
import { useIsFullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta.utils';
import { Icon } from '~/v1/components/icons/icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { getLinkIcon } from '~/v1/components/link/link.utils';
import { isInternalLink } from '~/v1/utils/isInternalLink';

import styles from './link.module.scss';

type NextLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseNextLinkProps> &
  BaseNextLinkProps & {
    children?: React.ReactNode;
  };

type LinkProps = {
  ariaLabel?: string;
  className?: string;
  isLarge?: boolean;
  withIcon?: boolean | IconType;

  /** Call `trackEvent` when the link is clicked */
  event?: TrackEventProps;

  /**
   * By default, arrow icons will be placed on the right and all other icons on the left.
   * @default 'auto'
   */
  iconPosition?: 'auto' | 'left' | 'right';

  children?: React.ReactNode;
} & NextLinkProps;

export const Link = forwardRef(LinkImpl);
function LinkImpl(
  {
    href,
    ariaLabel,
    className,
    isLarge,
    withIcon,
    event,
    onClick,
    iconPosition = 'auto',
    children,
    ...props
  }: LinkProps,
  forwardedRef: React.Ref<HTMLAnchorElement>,
) {
  const stringHref = typeof href === 'string' ? href : resolveHref(Router, href);
  const isInternal = isInternalLink(stringHref);

  let iconType: IconType | null = null;
  if (withIcon === true) {
    iconType = getLinkIcon(stringHref);
  } else if (withIcon) {
    iconType = withIcon;
  }

  const isArrowIcon =
    iconType === IconType.ExpandingArrowRight ||
    iconType === IconType.ExpandingArrowDiagonal ||
    iconType === IconType.ExpandingArrowLeft;
  const isRightIcon =
    iconPosition === 'right' ||
    (iconPosition === 'auto' &&
      // exclude left arrow
      (iconType === IconType.ExpandingArrowRight || iconType === IconType.ExpandingArrowDiagonal));

  const isFullBleed = useIsFullBleedCTATrigger(props);
  const linkClasses = cx(styles.link, className, {
    linkTypography: !isLarge,
    linkLargeTypography: isLarge,
    [styles.notIsFullBleed]: !isFullBleed,
  });

  const iconElement = iconType ? (
    <Icon
      type={iconType}
      className={cx(styles.icon, {
        [styles.arrow]: isArrowIcon,
        [styles.right]: isRightIcon,
      })}
    />
  ) : null;

  let content: React.ReactNode;
  if (typeof children === 'string') {
    const text = children.split(' ');
    if (isRightIcon) {
      const lastWord = text.pop();
      content = (
        <>
          {text.join(' ') + ' '}
          <span className={styles.noWrap}>
            {lastWord}&nbsp;{iconElement}
          </span>
        </>
      );
    } else {
      const firstWord = text.shift();
      content = (
        <>
          <span className={styles.noWrap}>
            {iconElement}&nbsp;{firstWord}
          </span>
          {' ' + text.join(' ')}
        </>
      );
    }
  } else {
    content = (
      <>
        {!isRightIcon && iconElement}
        {children}
        {isRightIcon && iconElement}
      </>
    );
  }

  return isInternal ? (
    <NextLink
      {...props}
      href={href}
      aria-label={ariaLabel}
      className={linkClasses}
      ref={forwardedRef}
      onClick={e => {
        if (event) {
          trackEvent(event);
        }
        onClick?.(e);
      }}
    >
      {content}
    </NextLink>
  ) : (
    <a
      href={stringHref}
      aria-label={ariaLabel}
      className={linkClasses}
      target="_blank"
      rel="noreferrer"
      ref={forwardedRef}
      {...props}
    >
      {content}
    </a>
  );
}
