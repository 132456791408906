import { formatDate as v2FormatDate } from '~/ui/utils/formatDate';
import { type FilterRangeInput } from '~/v1/_types/globalTypes';

export const formatDate = (date: string, pattern?: Intl.DateTimeFormatOptions): string => {
  const formattedDate = date.replace(/-/g, '/');
  return v2FormatDate(formattedDate, { pattern });
};

export const formatToDollars = (amount: number): string => {
  return Math.abs(amount) >= 1.0e9
    ? `$${Math.abs(amount) / 1.0e9}B`
    : // Six Zeroes for Millions
      Math.abs(amount) >= 1.0e6
      ? `$${Math.abs(amount) / 1.0e6}M`
      : // Three Zeroes for Thousands
        Math.abs(amount) >= 1.0e3
        ? `$${Math.abs(amount) / 1.0e3}K`
        : `${Math.abs(amount)}`;
};

export const formatAmount = (amount: FilterRangeInput): string => {
  return amount.gte && amount.lte
    ? `${formatToDollars(amount.gte)} - ${formatToDollars(amount.lte)}`
    : amount.gte
      ? `> ${formatToDollars(amount.gte)}`
      : amount.lte
        ? `< ${formatToDollars(amount.lte)}`
        : '';
};

export const dasherize = (slug: string): string => {
  return slug.replace(/[\s_]/g, '-').toLowerCase();
};

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const truncateTo = (limit: number, text: string): string => {
  return text.length > limit ? `${text.slice(0, limit)}...` : text;
};

export const localeToSlug = (locale: string | null): string => {
  return locale !== null && locale !== 'en-US' ? `/${locale}` : '';
};

export const lottieColors = (hex: string): number[] => {
  const result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const resultColor = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;

  const lottieRed = (resultColor?.r || 0) / 255;
  const lottieGreen = (resultColor?.g || 0) / 255;
  const lottieBlue = (resultColor?.b || 0) / 255;

  return [lottieRed, lottieGreen, lottieBlue];
};
