// ----- ATTENTION -----
//
// Do not make edits in this file, it is generated by a script.
// Check "generate-icons.js" for more details.
// To rebuild it, run "yarn generate-icons" from the root folder.

import ArrowDiagonal from '~/ui/assets/icons/arrow-diagonal.svg';
import ArrowDown from '~/ui/assets/icons/arrow-down.svg';
import ArrowLeft from '~/ui/assets/icons/arrow-left.svg';
import ArrowLevelDown from '~/ui/assets/icons/arrow-level-down.svg';
import ArrowRight from '~/ui/assets/icons/arrow-right.svg';
import ArrowUp from '~/ui/assets/icons/arrow-up.svg';
import Camera from '~/ui/assets/icons/camera.svg';
import CardLogo from '~/ui/assets/icons/card-logo.svg';
import CcOff from '~/ui/assets/icons/cc-off.svg';
import CcOn from '~/ui/assets/icons/cc-on.svg';
import Check from '~/ui/assets/icons/check.svg';
import Chevron from '~/ui/assets/icons/chevron.svg';
import Close from '~/ui/assets/icons/close.svg';
import Envelope from '~/ui/assets/icons/envelope.svg';
import ExpandingArrowDiagonal from '~/ui/assets/icons/expanding-arrow-diagonal.svg';
import ExpandingArrowLeft from '~/ui/assets/icons/expanding-arrow-left.svg';
import ExpandingArrowRight from '~/ui/assets/icons/expanding-arrow-right.svg';
import FacebookSquare from '~/ui/assets/icons/facebook-square.svg';
import Facebook from '~/ui/assets/icons/facebook.svg';
import Filter from '~/ui/assets/icons/filter.svg';
import Globe from '~/ui/assets/icons/globe.svg';
import GreatWallGrid from '~/ui/assets/icons/great-wall-grid.svg';
import GreatWallScroller from '~/ui/assets/icons/great-wall-scroller.svg';
import HeadphoneOff from '~/ui/assets/icons/headphone-off.svg';
import Headphone from '~/ui/assets/icons/headphone.svg';
import Instagram from '~/ui/assets/icons/instagram.svg';
import LineDiagonal from '~/ui/assets/icons/line-diagonal.svg';
import Line from '~/ui/assets/icons/line.svg';
import Link from '~/ui/assets/icons/link.svg';
import Linkedin from '~/ui/assets/icons/linkedin.svg';
import Loading from '~/ui/assets/icons/loading.svg';
import LocationPin from '~/ui/assets/icons/location-pin.svg';
import Lock from '~/ui/assets/icons/lock.svg';
import Logo404 from '~/ui/assets/icons/logo-404.svg';
import LogoFooter from '~/ui/assets/icons/logo-footer.svg';
import LogoLarge from '~/ui/assets/icons/logo-large.svg';
import LogoNews from '~/ui/assets/icons/logo-news.svg';
import LogoWorded from '~/ui/assets/icons/logo-worded.svg';
import Logo from '~/ui/assets/icons/logo.svg';
import Maximize from '~/ui/assets/icons/maximize.svg';
import Microphone from '~/ui/assets/icons/microphone.svg';
import Minimize from '~/ui/assets/icons/minimize.svg';
import Minus from '~/ui/assets/icons/minus.svg';
import Pause from '~/ui/assets/icons/pause.svg';
import Pdf from '~/ui/assets/icons/pdf.svg';
import Phone from '~/ui/assets/icons/phone.svg';
import Play from '~/ui/assets/icons/play.svg';
import Plus from '~/ui/assets/icons/plus.svg';
import Print from '~/ui/assets/icons/print.svg';
import Reload from '~/ui/assets/icons/reload.svg';
import Search from '~/ui/assets/icons/search.svg';
import Share from '~/ui/assets/icons/share.svg';
import Sort from '~/ui/assets/icons/sort.svg';
import Threads from '~/ui/assets/icons/threads.svg';
import Tick from '~/ui/assets/icons/tick.svg';
import Twitter from '~/ui/assets/icons/twitter.svg';
import Union from '~/ui/assets/icons/union.svg';
import User from '~/ui/assets/icons/user.svg';
import Volume from '~/ui/assets/icons/volume.svg';
import Youtube from '~/ui/assets/icons/youtube.svg';
import { ExpandingArrow } from '~/v1/components/expandingArrow/expandingArrow';

import { IconType } from './icon.interfaces';

const icons: Record<IconType, React.FC<React.SVGProps<SVGElement>>> = {
  ArrowDiagonal,
  ArrowDown,
  ArrowLeft,
  ArrowLevelDown,
  ArrowRight,
  ArrowUp,
  Camera,
  CardLogo,
  CcOff,
  CcOn,
  Check,
  Chevron,
  Close,
  Envelope,
  ExpandingArrowDiagonal,
  ExpandingArrowLeft,
  ExpandingArrowRight,
  FacebookSquare,
  Facebook,
  Filter,
  Globe,
  GreatWallGrid,
  GreatWallScroller,
  HeadphoneOff,
  Headphone,
  Instagram,
  LineDiagonal,
  Line,
  Link,
  Linkedin,
  Loading,
  LocationPin,
  Lock,
  Logo404,
  LogoFooter,
  LogoLarge,
  LogoNews,
  LogoWorded,
  Logo,
  Maximize,
  Microphone,
  Minimize,
  Minus,
  Pause,
  Pdf,
  Phone,
  Play,
  Plus,
  Print,
  Reload,
  Search,
  Share,
  Sort,
  Threads,
  Tick,
  Twitter,
  Union,
  User,
  Volume,
  Youtube,
};

type IconProps = {
  type: IconType;
};

export const Icon: React.FC<IconProps & React.HTMLAttributes<SVGElement>> = ({
  type,
  ...props
}) => {
  switch (type) {
    case IconType.ExpandingArrowLeft:
      return <ExpandingArrow direction="left" {...props} />;

    case IconType.ExpandingArrowDiagonal:
      return <ExpandingArrow direction="diagonal" {...props} />;

    case IconType.ExpandingArrowRight:
      return <ExpandingArrow direction="right" {...props} />;

    default:
      const Icon = icons[type];
      return <Icon {...props} />;
  }
};

export default icons;
